import { InputNumber, Select } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "../../re-usables/card/Card";
import { BASE_URL, STATIC_URL } from "../../../http/axiosConfig/config";

export default function ItemCard({
  item,
  indentNumber,
  index,
  updateValues,
  handleNotifyMe,
  readOnly,
  appFlavour,
}) {
  const navigate = useNavigate();
  const inr = <span style={{ fontFamily: "sans-serif" }}>&#x20B9;</span>;
  const [imageUrl, setImageUrl] = useState(
    `${STATIC_URL}/images/${item.IMG_PATH}`
  );

  return (
    <div>
      <Card>
        <img
          src={imageUrl}
          alt={item.PACKET_NAME}
          onError={(e) =>
            (e.target.src = require("../../../assets/images/error-loading-image.jpg"))
          }
          onClick={() =>
            indentNumber
              ? navigate("/product-details", {
                  state: {
                    packetCode: item?.PACKET_CODE,
                    rateCatag: indentNumber?.RATE_CATAG,
                  },
                })
              : null
          }
        />

        <div className="product-info">
          <div className="product-name">{item.PACKET_NAME}</div>
          <div style={{ display: "flex", flexDirection: "column", gap: 3 }}>
            {!readOnly ? (
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="price">
                    {inr} {item.RATE}
                  </div>
                  <div style={{ fontSize: 12, alignSelf: "flex-end" }}>
                    Qty in Pkts
                  </div>
                </div>
                <div
                  className="inputs-section"
                  onClick={(e) => e.stopPropagation()}
                >
                  {/* <Select
                    style={{ minWidth: 90 }}
                    value={item.INDENT_IN}
                    disabled={item.ALLOW_IN_BOTH === "N"}
                    onChange={(val) => updateValues(index, val, "dropDown")}
                  >
                    <Select.Option value="C">Crate</Select.Option>
                    <Select.Option value="P">Packets</Select.Option>
                  </Select> */}
                  <div
                    className="price"
                    style={{ minWidth: 100, fontSize: 12 }}
                  >
                    {item.CRATES === "Y" ? (
                      <div>{`${
                        item.PKT / item.CRATE_OR_NOT >= 1
                          ? Math.floor(item.PKT / item.CRATE_OR_NOT)
                          : 0
                      } Crts, ${item.PKT % item.CRATE_OR_NOT} Pkts`}</div>
                    ) : null}
                  </div>

                  <div className="quantity-selector">
                    <div
                      className="decrease"
                      onClick={() => {
                        let val = item.INDENT_IN === "P" ? item.PKT : item.QTY;
                        updateValues(index, Number(val) - 1);
                      }}
                    >
                      -
                    </div>
                    <div className="quantity">
                      <InputNumber
                        min={0}
                        max={item.FGS_QTY}
                        controls={false}
                        value={
                          item.INDENT_IN === "C"
                            ? Number(item.QTY) > 0
                              ? Number(item.QTY).toFixed(2)
                              : Number(item.QTY)
                            : Number(item.PKT) > 0
                            ? Number(item.PKT).toFixed(2)
                            : Number(item.PKT)
                        }
                        onChange={(val) => updateValues(index, Number(val))}
                      />
                    </div>
                    <div
                      className="increase"
                      onClick={() => {
                        let val = item.INDENT_IN === "P" ? item.PKT : item.QTY;
                        updateValues(index, Number(val) + 1);
                      }}
                    >
                      +
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div
                style={{
                  width: appFlavour === "MYMUL" ? "100%" : 144,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {appFlavour !== "MYMUL" ? (
                  <span>{item.INDENT_IN === "C" ? "Crate" : "Packets"}</span>
                ) : (
                  <span>
                    Rate: {inr} {item.RATE}
                  </span>
                )}
                {appFlavour === "MYMUL" ? (
                  <>
                    <span>Crt/Box: {item.QTY}</span>
                    <span>Qty: {item.PKT}</span>
                  </>
                ) : null}
              </div>
            )}
          </div>
          <div className="bottom-row">
            {item.LABEL ? (
              <div
                className="availability-label"
                onClick={() =>
                  item.LABEL === "Nofify Me"
                    ? handleNotifyMe(item.PACKET_CODE)
                    : null
                }
                style={{
                  color:
                    item.LABEL === "Available"
                      ? "#4E8B32"
                      : item.LABEL === "Nofify Me"
                      ? "#D92525"
                      : "#F8891A",
                  textDecoration:
                    item.LABEL === "Nofify Me" ? "underline" : "none",
                }}
              >
                {item.LABEL}
              </div>
            ) : null}

            <div className="price total-price" style={{ marginLeft: "auto" }}>
              {inr}{" "}
              {item.INDENT_IN === "C"
                ? (item.RATE * item.QTY * item.CRATE_OR_NOT).toFixed(2)
                : (item.RATE * item.PKT).toFixed(2)}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
