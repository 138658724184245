import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getRequest } from "../../../http/axiosConfig/config";
import urls from "../../../http/endpoints/urls";
import Card from "../../re-usables/card/Card";
import ListLoader from "../../re-usables/loaders/ListLoader";
import "./Order.scss";
import OrderItem from "./OrderItem";

export default function Order() {
  const inr = <span style={{ fontFamily: "sans-serif" }}>&#x20B9;</span>;
  const [cart, setCart] = useState([]);
  const [priceBreakdown, setPriceBreakdown] = useState([]);
  const [netAmount, setNetAmount] = useState("");
  const [finalOrderVal, setFinalOrderVal] = useState("");
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const creditLimit = JSON.parse(sessionStorage.getItem("creditLimit"));
  const appFlavour = process.env.REACT_APP_FLAVOUR;

  const getCartDetails = async () => {
    try {
      setLoading(true);
      let indentNumber = location.state.indentNumber;
      const response = await getRequest(urls.getCart(indentNumber));

      if (appFlavour !== "MYMUL") {
        const priceBreakdown = await getRequest(
          urls.getPriceBreakdown(indentNumber)
        );
        setPriceBreakdown(priceBreakdown[0]);
      }

      if (appFlavour !== "MYMUL") {
        const netAmount = await getRequest(urls.getNetAmount(indentNumber));
        setNetAmount(netAmount[0]);
      }
      const orderVal = await getRequest(urls.finalOrderValue(indentNumber));
      setCart(response[0]);
      setFinalOrderVal(orderVal[0]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getCartDetails();
  }, []);

  if (loading)
    return (
      <div style={{ height: "calc(100vh - 281px)", overflowY: "hidden" }}>
        <ListLoader />
      </div>
    );

  return (
    <div className="cart-items">
      <div style={{ fontWeight: 600 }}>Order Details</div>
      <div className="cart-items-scroll-area">
        {cart.length
          ? cart.map((item) => (
              <OrderItem item={item} rateCatag={location.state.rateCatag} />
            ))
          : null}
        <Divider />

        <div className={appFlavour === "MYMUL" ? "net-amount" : ""}>
          <div className="flex-row">
            <div className="key">Order Value</div>
            <div className="value" style={{ fontWeight: 600 }}>
              {inr} {Number(finalOrderVal.TOTAL).toFixed(2)}
            </div>
          </div>
        </div>

        <div className="price-breakdown">
          {priceBreakdown.length
            ? priceBreakdown.map((item) => (
                <div className="flex-row">
                  <div className="key">{item.ADD_DED_NAME}</div>
                  <div className="value">
                    {inr} {Number(item.AMT).toFixed(2)}
                  </div>
                </div>
              ))
            : null}

          {appFlavour !== "MYMUL" ? (
            <>
              <Divider />
              <div className="net-amount">
                <div className="flex-row">
                  <div className="key">Net Payable</div>
                  <div className="value">
                    {inr} {Number(netAmount.TOTAL).toFixed(2)}
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <div className="continue-btn">
        <button
          className="secondary-btn"
          onClick={() => navigate(-1, { replace: true })}
        >
          Back
        </button>
        {creditLimit[0].PAY_MODE === "B" && appFlavour !== "MYMUL" ? (
          <button
            className="primary-btn"
            onClick={() => {
              sessionStorage.setItem("initialPayment", true);
              return navigate("/payment", {
                state: { amount: netAmount.TOTAL },
                replace: true,
              });
            }}
          >
            Pay
          </button>
        ) : (
          <button
            className="primary-btn"
            onClick={() => {
              return navigate("/", { replace: true });
            }}
          >
            Continue
          </button>
        )}
      </div>
    </div>
  );
}
