import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  pdf,
} from "@react-pdf/renderer";
import { DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import { Spin } from "antd";
import { useState } from "react";
import { toast } from "react-toastify";

export default function DownloadReport(props) {
  const { columns, data, reportName, to, from } = props;
  const [downloading, setdownloading] = useState(false);
  const fileName =
    from && to
      ? `${reportName}_${from}_${to}`
      : `${reportName}_${moment().format("DD_MM_YY")}`;

  const createUrl = async () => {
    try {
      setdownloading(true);
      const blob = await pdf(
        <MyDocument
          columns={columns}
          data={data}
          reportName={reportName}
          to={to}
          from={from}
        />
      ).toBlob();

      // var reader = new FileReader();
      // reader.readAsDataURL(blob);
      // reader.onloadend = function () {
      //   var base64data = reader.result;
      //   console.log(base64data);
      // };

      const file = new File([blob], fileName, { type: "application/pdf" });
      let url = URL.createObjectURL(file);

      let a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = fileName;
      a.click();
      document.body.removeChild(a);
      console.log(url);
      URL.revokeObjectURL(file);

      setdownloading(false);
    } catch (error) {
      setdownloading(false);
      console.log(error);
      toast.error("File could not be saved, try again.");
    }
  };

  return (
    <div style={{ height: "100%" }}>
      {/* <PDFViewer width={"100%"} height={"100%"}>
        {console.log(to)}
        <MyDocument
          columns={columns}
          data={data}
          reportName={reportName}
          to={to}
          from={from}
        />
      </PDFViewer> */}
      {/* <PDFDownloadLink
        document={
          <MyDocument
            columns={columns}
            data={data}
            reportName={reportName}
            to={to}
            from={from}
          />
        }
        fileName={fileName}
      >
        {({ blob, url, loading, error }) => {
          return loading ? <Spin /> : <DownloadOutlined />;
        }}
      </PDFDownloadLink> */}
      {downloading ? <Spin /> : <DownloadOutlined onClick={createUrl} />}
    </div>
  );
}

const styles = StyleSheet.create({
  page: {
    fontSize: 13,
    padding: 20,
    paddingBottom: 40,
  },
  section: {
    marginTop: 20,
  },
  mainHeader: {
    fontSize: 16,
    textAlign: "center",
    textDecoration: "underline",
    fontWeight: 600,
  },
  subHeader: {
    marginTop: 15,
    fontSize: 15,
    fontWeight: 800,
    textAlign: "center",
  },
  dateRange: {
    marginTop: 3,
    fontSize: 10,
    textAlign: "center",
  },
  metaData: {
    fontSize: 12,
    marginTop: 5,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 15,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  tableHeader: {
    position: "relative",
    fontSize: 12,
    top: 0,
    left: 0,
    right: 0,
  },
});

const tableStyles = StyleSheet.create({
  table: {
    width: "100%",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid gray",
  },
  header: {
    borderTop: "none",
  },
  td: {
    padding: "5px 10px 5px 10px",
    borderLeft: "1px solid gray",
    fontSize: 9,
  },
  tdHighlighted: {
    backgroundColor: "#e8e8e8",
  },
  last: {
    borderRight: "1px solid gray",
  },
  bold: {
    fontWeight: "bold",
  },
});

const MyDocument = ({ columns, data, reportName, to, from }) => {
  const agentName = localStorage.getItem("userName");
  const agentCode = localStorage.getItem("agentCode");

  return (
    <Document>
      <Page size="A4" wrap={true} style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.mainHeader}>
            The Mandya District Co-Operative Milk Producer's Societies Union
            Limited
          </Text>
          <Text style={styles.subHeader}>{reportName}</Text>
          {from && to ? (
            <Text style={styles.dateRange}>{`(${from} - ${to})`}</Text>
          ) : null}
          <View
            style={{
              marginTop: 20,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View>
              <Text style={styles.metaData}>{`Agent Name: ${agentName}`}</Text>
              <Text style={styles.metaData}>{`Agent Code: ${agentCode}`}</Text>
            </View>
            <Text style={styles.metaData}>{`Date: ${moment().format(
              "DD MMM YYYY"
            )}`}</Text>
          </View>
        </View>
        <View style={styles.section}>
          <View style={styles.tableHeader} fixed>
            <View style={tableStyles.table}>
              <View style={[tableStyles.row, tableStyles.bold]} wrap={false}>
                {columns.map((col, i, arr) => (
                  <Text
                    style={
                      i === arr.length - 1
                        ? [
                            tableStyles.td,
                            tableStyles.tdHighlighted,
                            tableStyles.last,
                            { flex: 0.5 },
                          ]
                        : [
                            tableStyles.td,
                            tableStyles.tdHighlighted,
                            {
                              flex:
                                reportName !== "Online Transactions" &&
                                reportName !== "Ledger" &&
                                i === 0
                                  ? 2
                                  : 0.5,
                            },
                          ]
                    }
                  >
                    {col.title}
                  </Text>
                ))}
              </View>
            </View>
          </View>
          {data.map((item, i, dataArr) => (
            <View style={tableStyles.table}>
              <View
                style={[
                  tableStyles.row,
                  tableStyles.bold,
                  {
                    borderBottom:
                      i === dataArr.length - 1 ? "1px solid gray" : "none",
                  },
                ]}
                wrap={false}
              >
                {columns.map((col, i, arr) => (
                  <Text
                    style={
                      i === arr.length - 1
                        ? [tableStyles.td, tableStyles.last, { flex: 0.5 }]
                        : [
                            tableStyles.td,
                            {
                              flex:
                                reportName !== "Online Transactions" &&
                                reportName !== "Ledger" &&
                                i === 0
                                  ? 2
                                  : 0.5,
                            },
                          ]
                    }
                  >
                    {item[col.dataIndex]}
                  </Text>
                ))}
              </View>
            </View>
          ))}
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};
