import React from "react";
import { useNavigate } from "react-router-dom";
import Card from "../../re-usables/card/Card";

export default function Tile(props) {
  const navigate = useNavigate();

  return (
    <div
      className={"report-menu-item"}
      onClick={() => navigate(props.navigateTo)}
    >
      <Card>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {props.icon}
        </div>
        <div style={{ textAlign: "center", marginTop: 10, color: "black" }}>
          {props.title}
        </div>
      </Card>
    </div>
  );
}
