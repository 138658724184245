import React from "react";
import { Link } from "react-router-dom";

export default function SidebarLink({ path, label, closeSidebar }) {
  return (
    <div onClick={closeSidebar} style={{ marginBottom: 20 }}>
      <Link to={path}>
        <div className="link-item">
          <img
            src={require("../../../assets/images/bulletPoint.png")}
            alt="reports"
          />
          {label}
        </div>
      </Link>
    </div>
  );
}
