import React from "react";
import "./Card.scss";

export default function Card({ children, title, className }) {
  return (
    <div className={`card ${className ? className : ""}`}>
      {title ? (
        <div className="card-title" style={{ fontWeight: 600, fontSize: 17 }}>
          {title}
        </div>
      ) : null}
      {children}
    </div>
  );
}
