import { Button, Form, Input, Spin } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getRequest } from "../../../http/axiosConfig/config";
import urls from "../../../http/endpoints/urls";
import "./ChangePassword.scss";

export default function ChangePassword({ back }) {
  const [loading, setLoading] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [userId, setUserId] = useState(localStorage.getItem("agentCode"));
  const [otp, setOtp] = useState(null);
  const [form] = Form.useForm();
  const [otpForm] = Form.useForm();
  const navigate = useNavigate();

  const changePassword = async (values) => {
    try {
      setLoading(true);
      await getRequest(
        urls.changePassword(userId, values.password, otp[0].OTP)
      );
      toast.success("Your password have been updated");
      form.resetFields();
      setLoading(false);
      back ? back(false) : navigate("/", { replace: true });
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong... try again");
    }
  };

  const getOtp = async (userId) => {
    try {
      setLoading(true);
      const response = await getRequest(urls.getOtp(userId));
      setOtp(response);
      setUserId(userId);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong... OTP couldn't be sent.");
    }
  };

  const verifyOtp = (values) => {
    try {
      if (otp[0].OTP === values.otp) {
        setOtpVerified(true);
        otpForm.resetFields();
      } else {
        toast.error("The entered OTP do not match");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!otpVerified && userId) {
      getOtp(userId);
    }

    return () => {
      setOtpVerified(false);
    };
  }, []);

  return (
    <div className="change-password-page">
      <div className="page-header" style={{ marginBottom: 10 }}>
        Change Password
      </div>

      {!userId ? (
        <Form
          requiredMark={false}
          form={otpForm}
          style={{ marginTop: 15 }}
          onFinish={(values) => getOtp(values.userId)}
        >
          <Form.Item
            name="userId"
            label="Enter your user Id"
            rules={[
              {
                required: true,
                message: "Please enter user Id",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <div className="btn-container">
              {back ? (
                <Button
                  style={{ width: 150 }}
                  onClick={() => {
                    back(false);
                  }}
                >
                  Cancel
                </Button>
              ) : null}
              <Button type="primary" htmlType="submit" style={{ width: 150 }}>
                Get OTP
              </Button>
            </div>
          </Form.Item>
        </Form>
      ) : null}

      {userId && !otpVerified ? (
        <>
          <div style={{ color: "#a3a3a3" }}>
            An OTP has been sent to your registered mobile number.
          </div>
          <Form
            requiredMark={false}
            form={otpForm}
            style={{ marginTop: 15 }}
            onFinish={verifyOtp}
          >
            <span>Enter OTP</span>
            <Form.Item
              name="otp"
              label="Enter OTP"
              noStyle
              rules={[
                {
                  required: true,
                  message: "Please enter OTP",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item noStyle>
              {!loading ? (
                <div
                  style={{
                    fontSize: 11,
                    width: "100%",
                    textAlign: "right",
                    marginTop: 5,
                    marginBottom: 5,
                  }}
                  onClick={() => getOtp(userId)}
                >
                  Resend OTP
                </div>
              ) : null}
            </Form.Item>

            <Form.Item>
              <div className="btn-container">
                {back ? (
                  <Button
                    style={{ width: 150 }}
                    onClick={() => {
                      back(false);
                    }}
                  >
                    Cancel
                  </Button>
                ) : null}
                <Button type="primary" htmlType="submit" style={{ width: 150 }}>
                  Verify
                </Button>
              </div>
            </Form.Item>
          </Form>
        </>
      ) : loading ? (
        <Spin />
      ) : userId && otpVerified ? (
        <>
          <Form
            requiredMark={false}
            form={form}
            style={{ marginTop: 15 }}
            onFinish={changePassword}
          >
            <Form.Item
              name="password"
              label="New Password"
              rules={[
                {
                  required: true,
                  message: "Please enter new password",
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your new password",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The two passwords does not match")
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <div className="btn-container">
                {back ? (
                  <Button style={{ width: 150 }} onClick={() => back(false)}>
                    Cancel
                  </Button>
                ) : null}
                <Button type="primary" htmlType="submit" style={{ width: 150 }}>
                  Submit
                </Button>
              </div>
            </Form.Item>
          </Form>
        </>
      ) : null}
    </div>
  );
}
