import React from "react";
import Skeleton from "react-loading-skeleton";
export default function GridLoader() {
  let list = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

  let item = (
    <div style={{ height: 70, marginBottom: 10 }}>
      <Skeleton />
    </div>
  );

  return (
    <div
      style={{
        display: "grid",
        rowGap: 10,
        columnGap: 20,
        gridTemplateColumns: "repeat(auto-fit, minmax(70px, 1fr))",
      }}
    >
      {list.map((i) => item)}
    </div>
  );
}
