import { Spin } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getRequest, postRequest } from "../../../http/axiosConfig/config";
import urls from "../../../http/endpoints/urls";
import ChangePassword from "../changePassword/ChangePassword";
import "./Login.scss";

export default function Login() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const mainLogoURL =
    process.env.REACT_APP_FLAVOUR === "MANMUL"
      ? require("../../../assets/images/manmul-mainLogo.png")
      : require("../../../assets/images/chamul-mainLogo.png");
  const mainLogoWidth =
    process.env.REACT_APP_FLAVOUR === "MANMUL" ? 150.75 : 108.75;

  const getHeading = (flavour) => {
    switch (flavour) {
      case "MANMUL":
        return <p>The Mandya District</p>;
      case "CHAMUL":
        return <p>Chamarajanagara</p>;
      case "MYMUL":
        return <p>Mysuru</p>;

      default:
        break;
    }
  };

  const handleLogin = async () => {
    try {
      setLoading(true);
      if (!name || !password) throw new Error("Enter the user credentials");

      const jsonResponse = await postRequest(urls.login(name, password));

      if (jsonResponse.error === false) {
        localStorage.setItem("auth", true);
        localStorage.setItem("agentCode", jsonResponse.name);
        localStorage.setItem("userId", jsonResponse.id1);
        localStorage.setItem("agType", jsonResponse.ag_type);

        const customerDetails = await getRequest(
          urls.getCustomerDetails(jsonResponse.id1)
        );
        localStorage.setItem("userName", customerDetails[0].CUSTOMER_NAME);
        navigate("/", { replace: true });
      } else toast.error(jsonResponse.error_msg);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
      console.log(error.message);
    }
    // navigate("/notifications");
  };

  return (
    <div className="login-main">
      <div className="top-section">
        <div className="login-top-logo">
          <img src={mainLogoURL} alt="nandini logo" width={mainLogoWidth} />
        </div>
        <div
          className="tag-line"
          style={{
            marginTop: process.env.REACT_APP_FLAVOUR === "MANMUL" ? 0 : 10,
          }}
        >
          {getHeading(process.env.REACT_APP_FLAVOUR)}
          <p>Co-Operative Milk Producer's</p>
          <p>Societies Union Limited</p>
        </div>
      </div>
      <div className="middle-section">
        {showChangePassword ? (
          <>
            <ChangePassword back={setShowChangePassword} />
          </>
        ) : (
          <>
            <div className="login-form-header">LOGIN</div>
            <div className="login-form">
              <div className="login-form-item">
                <div className="login-input-label">Agent Code</div>
                <input
                  className="login-input"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="login-form-item">
                <div className="login-input-label">Password</div>
                <input
                  type="password"
                  className="login-input"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div
                className="forgot"
                onClick={() => setShowChangePassword(true)}
              >
                Forgot Password?
              </div>
              {loading ? (
                <Spin />
              ) : (
                <button
                  className="primary-btn"
                  onClick={handleLogin}
                  disabled={loading}
                >
                  Login
                </button>
              )}
            </div>
          </>
        )}
      </div>
      <div className="bottom-section">
        <span className="login-footer-text">Siri Technologies, Mysore</span>
        <span className="version-text">{process.env.REACT_APP_VERSION}</span>
      </div>
    </div>
  );
}
