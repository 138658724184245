import { DatePicker, Empty, Form, Spin, Table } from "antd";
import React from "react";
import { useState } from "react";
import { ReactComponent as CalendarIcon } from "../../../assets/icons/calendar-icon.svg";
import { getRequest } from "../../../http/axiosConfig/config";
import urls from "../../../http/endpoints/urls";
import DownloadReport from "./DownloadReport";

export default function Ledger() {
  let customerId = localStorage.getItem("userId");
  const [loading, setLoading] = useState(false);
  const [ledger, setLedger] = useState(null);
  const [dateForm] = Form.useForm();

  const getLedgerData = async (values) => {
    try {
      setLoading(true);
      let formattedFormDate = values.fromDate.format("DD-MMM-YY");
      let formattedToDate = values.toDate.format("DD-MMM-YY");
      let cb_dt = values.toDate.add(1, "days").format("DD-MMM-YY");
      const res = await getRequest(
        urls.ledgerData(customerId, formattedFormDate, formattedToDate, cb_dt)
      );
      setLedger(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const ledgerColumns = [
    { title: "Date", dataIndex: "INDENT_DATE", width: 100 },
    {
      title: "Particulars",
      dataIndex: "PERTICULARS",
      width: 150,
      align: "center",
    },
    {
      title: "Bill Amount",
      dataIndex: "BILL_AMOUNT",
      width: 100,
      align: "center",
    },
    {
      title: "Paid Amount",
      dataIndex: "PAID_AMOUNT",
      width: 110,
      align: "center",
    },
  ];

  return (
    <div>
      <div className="page-header">
        Ledger
        {/* {ledger && ledger.length && !loading ? (
          <DownloadReport
            columns={ledgerColumns}
            data={ledger}
            reportName="Ledger"
            to={dateForm.getFieldValue("toDate").format("DD/MM/YY")}
            from={dateForm.getFieldValue("fromDate").format("DD/MM/YY")}
          />
        ) : null} */}
      </div>
      <Form
        className="date-range-form"
        form={dateForm}
        layout={"horizontal"}
        onFinish={getLedgerData}
        requiredMark={false}
        style={{ marginTop: 10, marginBottom: 10 }}
      >
        <Form.Item
          noStyle
          name={"fromDate"}
          rules={[{ required: true, message: "Please select from date" }]}
        >
          <DatePicker suffixIcon={<CalendarIcon />} format="DD/MM/YY" />
        </Form.Item>
        To
        <Form.Item
          noStyle
          name={"toDate"}
          rules={[{ required: true, message: "Please select to date" }]}
        >
          <DatePicker suffixIcon={<CalendarIcon />} format="DD/MM/YY" />
        </Form.Item>
        <Form.Item noStyle>
          <button
            className="primary-btn"
            style={{
              width: 60,
              padding: 5,
              boxShadow: "none",
              borderRadius: "none",
            }}
            onClick={() => dateForm.submit()}
          >
            Go
          </button>
        </Form.Item>
      </Form>

      {!loading && ledger ? (
        <>
          <Table
            dataSource={loading ? [] : ledger}
            locale={{
              emptyText: loading ? <Spin /> : <Empty />,
            }}
            columns={ledgerColumns}
            bordered
            scroll={{ y: "calc(100vh - 420px)", x: 200 }}
            pagination={false}
          />
          <div style={{ fontWeight: 600, fontSize: 11, marginTop: 10 }}>
            {" "}
            Note: Subject to reconciliation
          </div>
        </>
      ) : null}
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin />
        </div>
      ) : null}
    </div>
  );
}
