import React, { useEffect, useState } from "react";
import "./Indents.scss";
import { getRequest } from "../../../http/axiosConfig/config";
import urls from "../../../http/endpoints/urls";
import ItemCard from "./ItemCard";
import ListLoader from "../../re-usables/loaders/ListLoader";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal, Spin } from "antd";
import { toast } from "react-toastify";

export default function Indents() {
  const inr = <span style={{ fontFamily: "sans-serif" }}>&#x20B9;</span>;
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const location = useLocation();
  const readOnly = location.state?.readOnly;
  const [indentItems, setIndentItems] = useState([]);
  const [indentNumber, setIndentNumber] = useState();
  const [orderValue, setOrderValue] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const selectedBatch = JSON.parse(sessionStorage.getItem("selectedBatch"));
  const creditLimit = JSON.parse(sessionStorage.getItem("creditLimit"));
  const appFlavour = process.env.REACT_APP_FLAVOUR;

  const getIndentNumber = async () => {
    try {
      setLoading(true);
      const indentNumber = await getRequest(
        urls.getIndentNumber(userId, selectedBatch.ROUTE_CODE)
      );
      const indentItems = await getRequest(
        urls.getIndentItems(indentNumber[0].INDENT_NUMBER)
      );
      const orderValue = await getRequest(
        urls.orderValue(indentNumber[0].INDENT_NUMBER)
      );
      // const myOrderValue = indentItems.result.reduce((prev, curr) => {
      //   let prod = curr.QTY * curr.RATE;
      //   return prev + prod;
      // }, 0);
      setOrderValue(Number(orderValue[0].TOTAL));
      setIndentNumber(indentNumber[0]);
      setIndentItems(indentItems.result);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const updateValues = (index, value, type = undefined) => {
    let objCopy = [...indentItems];
    let row = objCopy[index];
    let diff;
    let orderVal;
    if (type === "dropDown" && value !== row.INDENT_IN) {
      row.INDENT_IN = value;
      if (value === "P") {
        let ov = orderValue - row.QTY * row.RATE * row.CRATE_OR_NOT;
        setOrderValue(ov + row.PKT * row.RATE);
      } else {
        let ov = orderValue - row.PKT * row.RATE;
        setOrderValue(ov + row.QTY * row.RATE * row.CRATE_OR_NOT);
      }
    } else {
      if (value < 0) return;
      if (value > row.FGS_QTY) {
        toast.error(`${row.FGS_QTY} units in stock`, {
          toastId: "out-of-stock-error",
        });
        return;
      }

      if (row.INDENT_IN === "P") {
        diff = value - Number(row.PKT);
        orderVal =
          diff < 0
            ? orderValue - Math.abs(diff) * row.RATE
            : orderValue + Math.abs(diff) * row.RATE;
      } else {
        diff = value - Number(row.QTY);
        orderVal =
          diff < 0
            ? orderValue - Math.abs(diff) * row.CRATE_OR_NOT * row.RATE
            : orderValue + Math.abs(diff) * row.CRATE_OR_NOT * row.RATE;
      }
      if (
        diff > 0 &&
        creditLimit[0].FD_LOCK === "Y" &&
        orderVal > Number(creditLimit[0].CREDIT_LIMIT)
      ) {
        toast.error(`Adding this item will exceed the credit limit`, {
          toastId: "credit-limit-error",
        });
        return;
      }

      setOrderValue(orderVal);
      row.INDENT_IN === "C" ? (row.QTY = value) : (row.PKT = value);
    }
    objCopy.splice(index, 1, row);

    setIndentItems(objCopy);
  };

  const saveIndent = async () => {
    try {
      setLoading(true);
      let qtyArr = [];
      let idArr = [];
      let indentIn = [];
      indentItems.map((item) => {
        let qty = item.INDENT_IN === "C" ? String(item.QTY) : String(item.PKT);
        qtyArr.push(qty);
        idArr.push(item.PACKET_CODE);
        indentIn.push(item.INDENT_IN);
      });

      await getRequest(
        urls.saveIndent(indentNumber.INDENT_NUMBER, qtyArr, idArr, indentIn)
      );
    } catch (error) {
      toast.error("Could not save indent, try again.");
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    getIndentNumber();
  }, []);

  useEffect(() => {
    sessionStorage.setItem("orderValue", orderValue);
  }, [orderValue]);

  return (
    <div>
      <div className="action-bar">
        <div
          className="order-value"
          style={{ fontWeight: 500, color: "#7B7B7B" }}
        >
          Order Value:{" "}
          <span style={{ color: "#0052A0" }}>
            {" "}
            {inr} {orderValue?.toFixed(2)}
          </span>
        </div>
      </div>
      <div className="indent-list indent-list-scroll-area">
        {indentItems && indentItems.length
          ? indentItems.map((item, index) => (
              <ItemCard
                item={item}
                indentNumber={indentNumber}
                index={index}
                updateValues={updateValues}
                readOnly={readOnly}
                appFlavour={appFlavour}
              />
            ))
          : null}
        {loading ? <ListLoader /> : null}
      </div>
      <div className="continue-btn">
        {!readOnly ? (
          <button
            className="primary-btn"
            onClick={() => {
              if (
                creditLimit[0].FD_LOCK === "Y" &&
                orderValue > Number(creditLimit[0].CREDIT_LIMIT)
              ) {
                toast.error(`Credit limit exceeded`, {
                  toastId: "credit-limit-error",
                });
                return;
              }
              setIsModalOpen(true);
            }}
          >
            Continue
          </button>
        ) : (
          <div className="action-btns" style={{ width: "100%" }}>
            <button
              className="secondary-btn"
              onClick={() => navigate(-1, { replace: true })}
            >
              Back
            </button>
            <button
              className="primary-btn"
              onClick={() =>
                navigate("/order", {
                  state: {
                    indentNumber: indentNumber?.INDENT_NUMBER,
                    rateCatag: indentNumber?.RATE_CATAG,
                  },
                })
              }
            >
              View Order
            </button>
          </div>
        )}
      </div>

      <Modal
        className="bottom-modal"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="header-img-container">
          <img
            src={require("../../../assets/images/add-more.png")}
            alt="add-more"
            width={100}
            height={100}
          />
        </div>

        <p>Would you like to add more items?</p>
        {loading ? (
          <Spin style={{ display: "flex", justifyContent: "center" }} />
        ) : (
          <div className="action-btns">
            <button
              className="secondary-btn"
              onClick={() => {
                saveIndent();
                setTimeout(() => {
                  navigate("/order", {
                    state: {
                      indentNumber: indentNumber?.INDENT_NUMBER,
                      rateCatag: indentNumber?.RATE_CATAG,
                    },
                  });
                }, 1000);
              }}
            >
              No
            </button>
            <button
              className="primary-btn"
              onClick={() => {
                saveIndent();
                setTimeout(() => {
                  navigate("/categories", {
                    state: {
                      indentNumber: indentNumber?.INDENT_NUMBER,
                      rateCatag: indentNumber?.RATE_CATAG,
                    },
                  });
                }, 1000);
              }}
            >
              Yes
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
}
