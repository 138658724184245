import { Form, InputNumber, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getRequest } from "../../../http/axiosConfig/config";
import urls from "../../../http/endpoints/urls";

export default function ManualPayment() {
  const inr = <span style={{ fontFamily: "sans-serif" }}>&#x20B9;</span>;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [challanDetails, setChallanDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const appFlavour = process.env.REACT_APP_FLAVOUR;
  const creditLimit = JSON.parse(sessionStorage.getItem("creditLimit"));

  const handleSubmit = async (values) => {
    sessionStorage.setItem("initialPayment", true);
    let amount = values.amount;
    form.resetFields();
    return navigate("/payment", {
      state: { amount: amount, billNo: challanDetails[0].BILL_NO },
      replace: true,
    });
  };

  const getChallanDetails = async () => {
    try {
      setLoading(true);
      let customerId = localStorage.getItem("userId");
      const challanDetails = await getRequest(
        urls.getChallanDetails(customerId)
      );
      setChallanDetails(challanDetails);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (appFlavour === "MYMUL") {
      getChallanDetails();
    }
  }, []);

  return creditLimit[0].PAY_MODE === "C" ? (
    <div style={{ marginTop: 20, fontWeight: 600 }}>
      You are not authorized to make online transactions.
    </div>
  ) : (
    <>
      <div className="page-header">Make Payment</div>
      {appFlavour !== "MYMUL" ? (
        <div
          style={{
            height: "100%",
            display: "flex",
            marginTop: "100px",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <>
            <Form
              className="manual-payment-form"
              requiredMark={false}
              form={form}
              onFinish={handleSubmit}
            >
              <Form.Item
                name="amount"
                label={<h3>Enter amount</h3>}
                validateFirst={true}
                rules={[
                  {
                    required: true,
                    message: "Please enter an amount",
                  },
                  {
                    validator: (_, value) => {
                      if (value >= 1) return Promise.resolve();
                      else
                        return Promise.reject("Amount must be greater than 0");
                    },
                  },
                ]}
              >
                <InputNumber
                  addonBefore={
                    <string style={{ fontFamily: "sans-serif" }}>
                      {" "}
                      &#x20B9;
                    </string>
                  }
                  style={{ width: "100%" }}
                  precision={2}
                  controls={false}
                />
              </Form.Item>
            </Form>
            <button className="primary-btn" onClick={() => form.submit()}>
              Pay
            </button>
          </>
        </div>
      ) : (
        <>
          {challanDetails.length ? (
            <div
              style={{
                fontSize: 16,
                fontWeight: 500,
                margin: "10px 0px",
                borderBottom: "1px solid #8080804d",
              }}
            >
              Bill No. {challanDetails[0].BILL_NO}
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {" "}
              <Spin />
            </div>
          )}
          <div className="price-breakdown">
            {challanDetails.map((item) => (
              <div className="flex-row">
                <div className="key">{item.ADD_DED_NAME}</div>
                <div className="value">
                  {inr} {item.AMOUNT}
                </div>
              </div>
            ))}

            {!!challanDetails.length && (
              <>
                <div
                  className="flex-row"
                  style={{
                    fontSize: 16,
                    fontWeight: 500,
                    margin: "10px 0px",
                    borderTop: "1px solid #8080804d",
                  }}
                >
                  <div className="key">Net Payable</div>
                  <div className="value">
                    {inr} {challanDetails[0].BILL_AMOUNT}
                  </div>
                </div>

                <div
                  className="pay-btn-section"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 35,
                  }}
                >
                  <button
                    className="primary-btn"
                    onClick={() =>
                      handleSubmit({ amount: challanDetails[0].BILL_AMOUNT })
                    }
                  >
                    Pay
                  </button>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}
